<template>
  <v-card>
    <v-card-text>
      <v-form
        ref="updateDiplomaForm"
        v-model="validUpdateDiplomaForm"
        lazy-validation
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <slot name="title">
                <p class="title font-weight-bold text-center px-7 pt-5">
                  Asigna una constancia para este nivel de membresía
                </p>
              </slot>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <p class="text-justify subtitle-2 primary--text">
                Carga tu plantilla en formato de imagen JPG con dimensiones de
                3301x2550 pixeles para orientación horizontal y de 2550x3301
                pixeles para orientación vertical. Recuerda dejar espacio para
                ubicar el nombre de tu asistente.
              </p>
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="diplomaTemplateComputed"
                :accept="['image/jpg', 'image/jpeg', 'image/png']"
                placeholder="Selecciona un archivo"
                prepend-inner-icon="mdi-image-area"
                prepend-icon=""
                filled
                rounded
                :rules="diplomaFileRules"
                show-size
                @click:clear="closeConstancyDialog(true)"
                @change="getImage(changePicture)"
              >
              </v-file-input>
            </v-col>
            <v-col >
              <v-row>
                <v-col cols="9">
                  <v-text-field filled rounded hide-details v-model="testName">
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-menu
                    transition="scale-transition"
                    bottom
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        elevation="0"
                        color="#dbdbdb"
                        x-large
                        v-on="on"
                        v-bind="attrs"
                        class="text-capitalize rounded-lg"
                      >
                        <v-icon>mdi-menu-right</v-icon>
                        Preferencias
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-checkbox
                          v-model="textColorComputed"
                          false-value="white"
                          true-value="black"
                          :label="`Color de texto: ${
                            textColor == 'black' ? '(Negro)' : '(Blanco)'
                          }`"
                        ></v-checkbox>
                      </v-list-item>
                      <v-list-item>
                        <v-checkbox
                          v-model="showSpace"
                          label="Mostrar espacio delimitado"
                        ></v-checkbox>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" >
              <v-row>
                <v-col cols="6" sm="12" md="6">
                  <span>Alineación vertical: {{ marginTopComputed }} px</span>
                  <v-slider
                    v-model.number="marginTopComputed"
                    min="20"
                    :max="maxMarginTop"
                    class="align-center"
                    hide-details
                  >
                  </v-slider>
                </v-col>
                <v-col cols="6" sm="12" md="6">
                  <span
                    >Alineación Horizontal: {{ marginLeftComputed }} px</span
                  >
                  <v-slider
                    v-model.number="marginLeftComputed"
                    min="10"
                    :max="maxMarginLeft"
                    class="align-center"
                    hide-details
                  >
                  </v-slider>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" >
                  <span
                    >Tamaño del espacio delimitado
                    {{ nameDelimeterComputed }}</span
                  >
                  <v-slider
                    v-model="nameDelimeterComputed"
                    min="50"
                    :max="nameDelimiterMax"
                    class="align-center"
                    hide-details
                  >
                  </v-slider>
                <!-- <v-col cols="3">                
                  <span>Mostrar espacio</span>
                  <v-switch hide-details class="ma-0" v-model="showSpace">
                  </v-switch>
                </v-col> -->
            </v-col>

          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <!--Se hizo el calculo para poner las medidas a escala de una constancia de 3301x2550 pixeles-->
              <v-img
                :max-width="
                  orientationComputed == 'horizontal' ? '462px' : '357px'
                "
                :height="
                  orientationComputed == 'horizontal' ? '357px' : '462px'
                "
                :src="objectURL"
                class="rounded-lg"
                lazy-src="https://picsum.photos/id/11/10/6"
              >
                <!-- Horizontal align -->
                <div
                  :style="`{position: relative; text-align: center; color: ${textColor}; font-weight: bold;}`"
                >
                  <div
                    
                    :style="`
                    position: absolute; 
                    border: ${showSpace ? '1px solid red' : ''};
                    word-break: break-word;
                    width:  ${nameDelimeterComputed}px;
                    top: ${marginTop}px;
                    left: ${marginLeft}px;
                    font-size: 11px;
                    line-height: 13px;
                    `"
                  >
                    {{ testName }}
                  </div>
                </div>
              </v-img>
            </v-col>
          </v-row>
          <v-row
            class="d-flex pb-2 mt-5"
            :class="
              $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
            "
          >
            <v-btn
              class="font-weight-bold black--text mx-2"
              color="grey lighten-3"
              rounded
              elevation="0"
              @click="closeConstancyDialog()"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="font-weight-bold black--text mx-2"
              color="accent"
              rounded
              elevation="0"
              :disabled="
                objectURL == 'https://via.placeholder.com/300x300.png?text=W' ||
                marginTop == '' ||
                marginLeft == '' ||
                !validUpdateDiplomaForm
              "
              @click="saveConstancy()"
            >
              Guardar
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "UpdateDiplomaDialog",
  props: {
    marginTop: {
      type: Number,
      default: 0,
    },
    marginLeft: {
      type: Number,
      default: 0,
    },
    nameDelimeter: {
      type: Number,
      default: 0,
    },
    textColor: {
      type: String,
      default: "black",
    },
    orientation: {
      type: String,
      default: "horizontal",
    },
    objectURL: {
      type: String,
      default: "https://via.placeholder.com/300x300.png?text=W",
    },
    diplomaTemplate: {
      type: File,
      default: null,
    },
  },
  data() {
    return {
      validUpdateDiplomaForm: false,
      dimentions: [3301, 2550],
      testName: "Diego Manuel Ortiz Arenas",
      showSpace: true,
      diplomaFileRules: [
        (value) => {
          if (value && value.size) {
            return (
              !value ||
              value.size < 5000000 ||
              "El archivo no debe de pesar más de 5MB"
            );
          } else {
            return true;
          }
        },
      ],
    };
  },
  computed: {
    nameDelimiterMax() {
      
      if (this.marginLeftComputed <= 10) {
        return 442;
      } else if (this.marginLeftComputed >= 320) {
        return 131;
      } else {
       
        const range = 320 - 10;
        const scale = (this.marginLeftComputed - 10) / range;
        return 442 - ((442 - 131) * scale);
      }
    },
    maxMarginTop() {
    
    return this.orientationComputed === 'horizontal' ? 335 - 10 : 330 - 10;
  },
  maxMarginLeft() {
   
    return this.orientationComputed === 'horizontal' ? 330 - 10 : 255 - 10;
  }
,




    marginTopComputed: {
      get() {
        return this.marginTop;
      },
      set(value) {
        this.$emit("setMarginTop", value);
      },
    },
    marginLeftComputed: {
      get() {
        return this.marginLeft;
      },
      set(value) {
        this.$emit("setMarginLeft", value);
      },
    },
    nameDelimeterComputed: {
      get() {
        return this.nameDelimeter;
      },
      set(value) {
        this.$emit("setNameDelimeter", value);
      },
    },
    textColorComputed: {
      get() {
        return this.textColor;
      },
      set(value) {
        this.$emit("setTextColor", value);
      },
    },
    orientationComputed: {
      get() {
        return this.orientation;
      },
      set(value) {
        this.$emit("setOrientation", value);
      },
    },
    diplomaTemplateComputed: {
      get() {
        return this.diplomaTemplate;
      },
      set(value) {
        this.$emit("setDiplomaTemplate", value);
      },
    },
    objectURLComputed: {
      get() {
        return this.objectURL;
      },
      set(value) {
        this.$emit("setObjectURL", value);
      },
    },
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    closeConstancyDialog(close) {
      this.$emit("closeConstancyDialog", close);
    },
    saveConstancy() {
      this.$emit("saveConstancy");
    },

    getImage(callback) {
      setTimeout(() => {
        if (this.diplomaTemplateComputed) {
          const blob = URL.createObjectURL(this.diplomaTemplateComputed);
          let img = new Image();
          img.src = blob;
          img.onload = function () {
            callback(this.width, this.height, blob);
          };
        }
      }, 100);
    },
    changePicture(x, y, url) {
      if (this.dimentions.includes(x) && this.dimentions.includes(y)) {
        x > y
          ? (this.orientationComputed = "horizontal")
          : (this.orientationComputed = "vertical");
        this.objectURLComputed = url;
      } else {
        this.show({
          text: "Las medidas de la imagen no son apropiadas para la constancia, Algunas veces pueder ser la orientación",
          color: "error",
        });
        this.diplomaTemplateComputed = null;
      }
    },
  },
};
</script>

<style>
</style>